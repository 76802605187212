<template>
  <Header />
  <MainMenu :navigation="data?.navigation" class="sticky top-16" />
  <div class="flex min-h-[calc(100dvh-105px)] flex-col">
    <main class="flex-1">
      <slot />
    </main>
    <Footer :footer="data?.footer" />
  </div>
</template>
<script setup lang="ts">
import { DefaultLayoutDocument } from "@graphql";

const { locale } = useI18n();

const { data } = await useAsyncQuery({
  query: DefaultLayoutDocument,
  variables: computed(() => ({
    languageCode: toLanguageCode(locale.value),
  })),
});
</script>
